

















import { defineComponent, ref } from '@nuxtjs/composition-api';
import useUser from '~/modules/customer/composables/useUser';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'NotifyMe',
  props: {
    product: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const {
      toggleMakeOfferModal,
      setNotifyMe,
      setNotifyMeSubmitted,
      setProdInfo,
    } = useUiState();
    const {
      isAuthenticated,
      productAlertNotifyInStock,
    } = useUser();
    const notifyLoading = ref(false);
    const productAlertNotifyInStockCTA = async (product) => {
      if (isAuthenticated.value) {
        notifyLoading.value = true;
        await productAlertNotifyInStock(product.id);
        notifyLoading.value = false;
        toggleMakeOfferModal()
        setNotifyMe()
        setNotifyMeSubmitted(true)
      } else {
        setProdInfo([{ name: product.name, sku: product.sku, id: product.id }]);
        setNotifyMe()
        toggleMakeOfferModal()
      }
    };
    return {
      productAlertNotifyInStockCTA,
      notifyLoading
    }
  }
})
